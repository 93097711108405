.MenuIconBtn {
  height: 40px;
  border: 0;
  background-color: transparent;
}

.MenuIconOpen .MenuIconBars {
  background-color: transparent;
}

.MenuIconBarsDark,
.MenuIconBarsDark:after,
.MenuIconBarsDark:before {
  display: block;
  background-color: #ffffff !important;

  transition: 0.3s;
}

.MenuIconBarsLight,
.MenuIconBarsLight:after,
.MenuIconBarsLight:before {
  display: block;
  background-color: #000000 !important;

  transition: 0.3s;
}

.MenuIconBars {
  position: relative;
  width: 25px;
  height: 3px;
}

.MenuIconOpen .MenuIconBars:before {
  transform: rotate(45deg);
}

.MenuIconOpen .MenuIconBars:after {
  transform: rotate(-45deg);
}

.MenuIconBars:after,
.MenuIconBars:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.MenuIconBars:before {
  transform: translateY(-10px);
}

.MenuIconBars:after {
  transform: translateY(10px);
}
