body,
html,
#root {
  height: 100%;
  margin: 0;
  overflow: hidden;
  background-color: #fafafa;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.coreCursor {
  cursor: url("../images/core.png"), auto;
}

.cursor-up {
  cursor: url("../images/arrow-up.png"), auto;
}

.cursor-up-left {
  cursor: url("../images/arrow-up-left.png"), auto;
}

.cursor-up-right {
  cursor: url("../images/arrow-up-right.png"), auto;
}

.cursor-down {
  cursor: url("../images/arrow-down.png"), auto;
}

.cursor-down-left {
  cursor: url("../images/arrow-down-left.png"), auto;
}

.cursor-down-right {
  cursor: url("../images/arrow-down-right.png"), auto;
}

.cursor-left {
  cursor: url("../images/arrow-left.png"), auto;
}
.cursor-right {
  cursor: url("../images/arrow-right.png"), auto;
}

.arrow-down {
  user-select: none;
  transform: rotate(-270deg);
  animation: ArrowDown 1s linear infinite;
  position: absolute;
  top: 95%;
  left: 36%;
}

@keyframes ArrowDown {
  0%,
  100% {
    top: 95%;
  }
  50% {
    top: 110%;
  }
}

.arrow-up {
  user-select: none;
  transform: rotate(270deg);
  animation: ArrowUp 1s linear infinite;
  position: absolute;
  top: -95%;
  left: 36%;
}

@keyframes ArrowUp {
  0%,
  100% {
    top: -95%;
  }
  50% {
    top: -110%;
  }
}

.arrow-left {
  user-select: none;
  transform: rotate(180deg);
  animation: ArrowLeft 1s linear infinite;
  position: absolute;
  top: 0;
  left: -85%;
}

@keyframes ArrowLeft {
  0%,
  100% {
    left: -85%;
  }
  50% {
    left: -100%;
  }
}

.arrow-right {
  user-select: none;
  animation: ArrowRight 1s linear infinite;
  position: absolute;
  top: 0;
  left: 150%;
}

@keyframes ArrowRight {
  0%,
  100% {
    left: 150%;
  }
  50% {
    left: 165%;
  }
}

@keyframes floatLeft {
  0%,
  100% {
    left: 0;
  }
  50% {
    left: 15px;
  }
}
@keyframes floatRight {
  0%,
  100% {
    right: 0;
  }
  50% {
    right: 15px;
  }
}
@keyframes floatUp {
  0%,
  100% {
    top: 0;
  }
  50% {
    top: 15px;
  }
}
@keyframes floatDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 15px;
  }
}

.btn-contact-white:hover {
  background-color: #fff !important;
  color: #000 !important;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .mobile-sections {
    min-height: -webkit-fill-available !important;
  }
}

.noEffect:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
}
