.logo {
  transition: all 0.3s ease;
}

@media screen and (min-width: 769px) and (max-width: 975px) {
  .logo {
    width: 180px;
  }
}

@media screen and (min-width: 975px) {
  .logo {
    width: 208px;
  }
}
