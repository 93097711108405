.hover_link {
  transition: all 0.4s ease !important;
}

.hover_link:hover {
  color: #444 !important;
}

.icon_hover {
  transition: all 0.4s ease !important;
}

.hover_link:hover .icon_hover {
  color: #fce72d;
}
